.auth-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

  form{
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    .checkbox {
      font-weight: 400;
    }
    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
      margin-bottom: -1px;
      border-radius: 0;
    }
    .form-control:focus {
      z-index: 2;
    }
    input:first-of-type{
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    input:last-of-type{
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

  }
}